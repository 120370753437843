import { Component, OnDestroy, NgZone } from '@angular/core';
import { LauncherService } from '../../launcher/launcher.service';
import { TranslateService } from 'ng2-translate';

@Component({
    selector: 'spin',
    templateUrl: 'spin.component.html'
})

export class SpinComponent {
    constructor(
        public launcher: LauncherService) { }
}