import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Error } from 'app/shared/error.model';
import {  } from '@angular/core/src/metadata/lifecycle_hooks';

@Component({
    selector: 'error',
    templateUrl: 'error.component.html'
})

export class ErrorComponent {
    @Input() errors: Error[] = [];
}