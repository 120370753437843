import { Component, OnInit } from '@angular/core';
import { LauncherService } from '../../launcher/launcher.service';

@Component({
    moduleId: module.id,
    selector: 'footer',
    templateUrl: 'footer.component.html'
})

export class FooterComponent {
    date: Date = new Date();
    
    constructor(
		public launcher: LauncherService) { }
}