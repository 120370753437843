import { Component, AfterContentInit, OnDestroy, NgZone } from '@angular/core';

import { Topic, Post } from './forum/topic.model';

import { ForumService } from './forum/forum.service';
import { LauncherService } from './launcher/launcher.service';
import { TranslateService } from 'ng2-translate';
import { UserProfile } from './user/user.profile';
import { UserService } from './user/user.service';

@Component({
	selector: '[main]',
	templateUrl: './app.component.html'
})
export class AppComponent implements AfterContentInit  {

	constructor(
		private launcher: LauncherService) { }

	ngAfterContentInit(): void {
		this.launcher.loaded();
	}
}
