import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'replace', pure: false })
export class ReplacePipe implements PipeTransform {
    constructor() {  }
    
    transform(original: string, src: string, dst: string): string {
        return original.replace(src, dst);
    }
}
