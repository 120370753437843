import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
	transform(value: string, limit = 25, completeWords = true, ellipsis = '…') {
		let lastindex = limit;
		if (completeWords) {
			lastindex = value.substr(0, limit).lastIndexOf(' ');
		}
		if (value.length > limit) {
			return `${value.substr(0, limit)}${ellipsis}`;
		}
		return `${value}`;
	}
}