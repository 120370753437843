import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';

import { Topic } from './topic.model'

@Injectable()
export class ForumService {
    constructor(
        private http: HttpClient) { }

    getNews() {
        return this.http.get('/api/forum/news')
            .pipe(map(response => { return <Topic[]>response; }));
    }

    getTopic(id: number) {
        return this.http.get('/api/forum/' + id)
            .pipe(map(response => { return <Topic>response; }));
    }

    getRules() {
        return this.http.get('/api/forum/rules')
            .pipe(map(response => { return <Topic>response; }));
    }

    getRuleTime() {
        return this.http.get('/api/forum/ruleTime')
            .pipe(map(response => { return <number>response; }));
    }

    getSubjects() {
        return this.http.get('/api/forum/subjects')
            .pipe(map(response => { return <Topic[]>response; }));
    }
}
