import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from 'ng2-translate';

import { ErrorComponent } from './shared/error.component';
import { HeaderComponent } from './shared/header.component';
import { FooterComponent } from './shared/footer.component';
import { LinkEffet1Component } from './shared/link-effect-1.component';
import { LinkEffet4Component } from './shared/link-effect-4.component';
import { LoadingComponent } from './shared/loading.component';
import { SpinComponent } from './shared/spin.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule
    ],
    exports: [
        ErrorComponent,
        FooterComponent,
        HeaderComponent,
        LinkEffet1Component,
        LinkEffet4Component,
        LoadingComponent,
        SpinComponent
    ],
    declarations: [
        ErrorComponent,
        FooterComponent,
        HeaderComponent,
        LinkEffet1Component,
        LinkEffet4Component,
        LoadingComponent,
        SpinComponent
    ],
    providers: [],
})
export class ElementModule { }
