import { Injectable } from "@angular/core";

declare const $: any;
const base = 'https://forum.rappelz-equation.fr';

export class Topic {
    tid: number;
    title: string;
    state: string;
    posts: number | null;
    starterId: number;
    startDate: number | null;
    lastPosterId: number;
    lastPost: number;
    starterName: string;
    lastPosterName: string;
    pollState: string;
    lastVote: number | null;
    views: number | null;
    forumId: number;
    approved: boolean;
    authorMode: boolean | null;
    pinned: boolean | null;
    movedTo: string;
    topicFirstpost: number;
    topicQueuedposts: number;
    topicOpenTime: number;
    topicCloseTime: number;
    topicRatingTotal: number;
    topicRatingHits: number;
    titleSeo: string;
    movedOn: number;
    topicArchiveStatus: number;
    lastRealPost: number;
    topicAnsweredPid: number;
    popularTime: number | null;
    featured: boolean | null;
    questionRating: number | null;
    topicHiddenposts: number | null;
    topicMetaData: boolean;

    //Posts
    comments: Post[];

    /**
     * Return author name.
     */
    getAuthor(): string {
        return this.starterName;
    }

    /**
     * Return url to author.
     */
    getAuthorUrl(): string {
        return base + '/index.php?/user/' + this.starterId + '-' + this.starterName + '/';
    }
    
    /**
     * Return topic date.
     */
    getDate(): Date {
        return new Date(this.startDate * 1000);
    }

    /**
     * Return topic content.
     */
    getContent(lang: string) {
        return this.getPostElement('div#' + lang + '_content', this.getFirstPost());
    }

    /**
     * Return post count.
     */
    getFirstPost(): string {
        return this.normalizeContent(this.comments[0].post);
    }
    
    /**
     * Return post count.
     */
    getPostCount(): number { 
        return (this.comments.length - 1);
    }

    /**
     * Return element in the post.
     */
    getPostElement(element: string, content: string): string {
        try {
            var post = this.getFirstPost();
            var elem = $(post).find(element);

            return elem.get(0).outerHTML;

        } catch (e) {
            return content;
        }
    }

    /**
     * Return topic title.
     */
    getTitle(lang: string) {
        return this.getPostElement('div#' + lang + '_title', this.title)
    }

    /**
     * Return topic url.
     */
    getUrl(): string {
        return base + '/index.php?/topic/' + this.tid + '-' + this.titleSeo + '/';
    }

    /**
     * Return normalized content.
     */
    normalizeContent(content: string) {
        content = content.replace(/<a /g, '<a target=\'blank\'');
        content = content.replace(/<___base_url___>/g, base);
        content = content.replace(/<fileStore.core_Emoticons>/g, base + '/uploads');
        content = content.replace(/<fileStore.core_Attachment>/g, base + '/uploads');
        return content;
    }
}

export class Post {
    pid: number;
    appendEdit: boolean | null;
    editTime: number | null;
    authorId: number;
    authorName: string;
    //ipAddress: string;
    postDate: number | null;
    post: string;
    queued: boolean;
    topicId: number;
    newTopic: boolean | null;
    editName: string;
    //postKey: string;
    postHtmlstate: number;
    postEditReason: string;
    postBwoptions: number;
    pdeleteTime: number;
    postFieldInt: number | null;
    postFieldT1: string;
    postFieldT2: string;

    /**
     * Return author name.
     */
    getAuthor(): string {
        return this.authorName;
    }

    /**
     * Return url to author.
     */
    getAuthorUrl(): string {
        return base + '/index.php?/user/' + this.authorId + '-' + this.authorName + '/';
    }
    
    /**
     * Return post date.
     */
    getDate(): Date {
        return new Date(this.postDate * 1000);
    }
}