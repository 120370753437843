import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ForumService } from './forum.service';

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [],
    declarations: [],
    providers: [
        ForumService
    ],
})
export class ForumModule { }
