import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CustomFormsModule } from 'ng2-validation';
import { HttpModule, Http } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateStaticLoader, TranslateLoader } from 'ng2-translate';
import { PopoverModule } from 'ngx-popover';
import { ForumModule } from './forum/forum.module';
import { UserModule } from './user/user.module';
import { ElementModule } from './element/element.module';
import { LauncherModule } from './launcher/launcher.module';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { LoginComponent } from './page/login.component';
import { HomeComponent } from './page/home.component';
import { HeaderComponent } from './element/shared/header.component';
import { FooterComponent } from './element/shared/footer.component';
import { NewsComponent } from './page/news.component';
import { SubjectsComponent } from './page/subjects.component';
import { RuleComponent } from './page/rule.component';

import { DateDayPipe } from './_pipes/date-day.pipe';
import { KeepHtmlPipe } from './_pipes/keep-html.pipe';
import { ReplacePipe } from './_pipes/replace.pipe';
import { TruncatePipe } from './_pipes/truncate.pipe';

import { InterceptorService } from './interceptor.service';
import { TopicViewComponent } from './page/topic-view.component';

export function createTranslateLoader(http: Http): TranslateStaticLoader {
	return new TranslateStaticLoader(http, './i18n', '.json' + '?cb=' + (new Date()).getTime());
}

@NgModule({
  bootstrap: [
    AppComponent,
    HeaderComponent,
    FooterComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    CommonModule,
    CustomFormsModule,
    ElementModule,
    FormsModule,
    ForumModule,
    HttpModule,
    HttpClientModule,
    LauncherModule,
    PopoverModule,
    TranslateModule.forRoot({
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [Http]
    }),
    UserModule
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    DateDayPipe,
    HomeComponent,
    NewsComponent,
    SubjectsComponent,
    TopicViewComponent,
    KeepHtmlPipe,
    ReplacePipe,
    TruncatePipe,
    RuleComponent
  ],
  providers: [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: InterceptorService,
        multi: true
    }
  ]
})
export class AppModule { }
