import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LauncherService } from './launcher.service';

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [],
    declarations: [],
    providers: [
        LauncherService
    ],
})
export class LauncherModule { }
