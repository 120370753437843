import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dateDay', pure: false })
export class DateDayPipe implements PipeTransform {
  constructor() {  }

  transform(date: number) {
    var time = Date.now() / 1000;
    return Math.round((time - date) / 86400);
  }
}