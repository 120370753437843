import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { ForumService } from './../forum/forum.service';
import { TranslateService } from 'ng2-translate';
import { LauncherService } from '../launcher/launcher.service';
import { UserService } from '../user/user.service';
import { UserProfile } from '../user/user.profile';


@Component({
    moduleId: module.id,
    templateUrl: 'home.component.html'
})

export class HomeComponent implements OnInit, OnDestroy {
    completed: boolean;
    loading: boolean;
    ruleExpire: boolean;

    private subscription: Subscription = new Subscription();

	constructor(
        private forum: ForumService,
        public launcher: LauncherService,
        private profile: UserProfile,
        private translate: TranslateService,
        private user: UserService) { }

    ngOnInit() {
        this.subscription.add(this.forum.getRuleTime().subscribe(
            response => {
                if (response > this.profile.user.ruleAccept) {
                    this.ruleExpire = true;
                }
            }
        ));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
    
    game() {
        this.subscription.add(this.user.requestImbc(this.launcher.env).subscribe(
            response => this.onSuccess(response),
            error =>    this.onError(error)
        ))
    }

    onError(errors: any) {
        errors.forEach(r => {
            this.subscription.add(this.translate.get('Error.' + r.code).subscribe(
                result => this.launcher.error(result)
            ));
        });
        this.loading = false;
    }

    onSuccess(token: any) {
        if (token.succeeded) {
            this.launcher.start(this.profile.user.userName, token.password, this.translate.currentLang);
        } else {
            this.subscription.add(this.translate.get('Error.RetrievingToken').subscribe(
                result => this.launcher.error(result)
            ));
        }
        this.loading = false;
    }
}