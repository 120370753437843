import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { Error } from './../shared/error.model';

import { LauncherService } from '../launcher/launcher.service';
import { UserService } from './../user/user.service';
import { TranslateService } from 'ng2-translate';

@Component({
    moduleId: module.id,
    templateUrl: 'login.component.html'
})

export class LoginComponent implements OnInit, OnDestroy {
    loading: boolean = false;
    model: any = {};

    private subscription: Subscription = new Subscription();

    constructor(
        private launcher: LauncherService,
        private router: Router,
        private translate: TranslateService,
        private user: UserService) {
            this.model.username = localStorage.getItem('username');
        }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngOnInit() {
    }

    login() {
        this.loading = true;
        this.subscription.add(this.user.login(this.model.username, this.model.password).subscribe(
            response => this.onSuccess(),
            error =>    this.onError(error)
        ));
    }

    onError(errors: any) {
        errors.forEach(r => {
            this.subscription.add(this.translate.get('Error.' + r.code).subscribe(
                result => this.launcher.error(result)
            ));
        });
        this.loading = false;
    }

    onSuccess() {
        localStorage.setItem('username', this.model.username);

        this.model = {};
        this.router.navigate(['home']);
        this.loading = false;
    }
}