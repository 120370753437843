export class IProfile {
    claims: IClaim[];
    language: string;
    token: string;
    tokenExpiration: string;
    refreshToken: string;
    refreshTokenExpiration: string;
    roles: string[];
    user: IUser;
}

export class IClaim {
    type: string;
    value: string;
}

export class IUser {
    id: number;
    avatar: string;
    banned: boolean;
    blockoutEnd: boolean;
    create: Date;
    email: string;
    emailConfirmed: boolean;
    language: string;
    money: number;
    pseudo: string;
    pseudoLockoutEnd: string;
    phoneNumber: string;
    phoneNumberConfirmed: boolean;
    ruleAccept: number;
    status: string;
    userName: string;
    vote: number;
}

export class UserProfile {
    avatar: string;
    id: number;
    lastActivity: Date;
    pseudo: string;
    status: string;
    roles: string[];
}