import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from 'ng2-translate';

import { UserService } from './user.service';

import { AuthGuard } from './auth.guard';

@NgModule({
    imports: [
        RouterModule,
        TranslateModule
    ],
    exports: [],
    declarations: [],
    providers: [
        AuthGuard,
        UserService
    ],
})
export class UserModule { }
