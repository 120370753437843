import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { Topic } from './../forum/topic.model';

import { ForumService } from './../forum/forum.service';

@Component({
    selector: '[news]',
    templateUrl: 'news.component.html'
})

export class NewsComponent implements OnInit, OnDestroy {
    loading: boolean = true;
	topics: Topic[] = [];

    private subscription: Subscription = new Subscription();

    constructor(
        private service: ForumService) { }

    ngOnInit() {
        this.subscription.add(this.service.getNews().subscribe(
			response =>response.forEach(u => this.topics.push(Object.assign(new Topic(), u))),
            error => {},
            () => this.loading = false
        ));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
