import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserProfile } from '../user/user.profile';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private userProfile: UserProfile) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.userProfile.isAuthorized()) {            
            return true;
        }

        this.router.navigate(['']);
        return false;
    }
}