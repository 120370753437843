import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'keepHtml', pure: false })
export class KeepHtmlPipe implements PipeTransform {
  constructor() {  }

  transform(content: string) {
    return content.trim()
      .replace(/\s{2,}/g, ' ')
      .replace(/<[^>]+>/gm, '');
  }
}