import { Component, AfterViewInit } from '@angular/core';

import { LauncherService } from '../../launcher/launcher.service';
import { TranslateService } from 'ng2-translate';
import { UserProfile } from './../../user/user.profile';
import { UserService } from '../../user/user.service';
import { Router } from '@angular/router';

declare var Godlike:any;

@Component({
    moduleId: module.id,
    selector: 'header',
    templateUrl: 'header.component.html'
})

export class HeaderComponent implements AfterViewInit {
    constructor(
        private profile: UserProfile,
        private launcher: LauncherService,
        private translate: TranslateService,
        private router: Router,
        private user: UserService) { }

    ngAfterViewInit() {
        Godlike.initNavbar();
        Godlike.initNavbarSide();
        Godlike.initNavbarFullscreen();
        Godlike.initNavbarDropEffect1();
        Godlike.initPluginStickySidebar();
    }

    changeLanguage(lang: string) {
        this.user.changeLanguage(lang);
    }

    close() {
        this.launcher.close();
    }

    language() {
        return this.translate.currentLang;
    }

    login() {
        return this.profile.isAuthorized();
    }

    logout() {
        this.user.logout();
        this.router.navigate(['']);
    }

    minimize() {
        this.launcher.minimize();
    }

    open(url: string) {
        this.launcher.navigate(url);
    }

    setting() {
        this.launcher.setting(this.translate.currentLang);
    }
}