import { Injectable, NgZone, OnDestroy } from '@angular/core';

declare var CefSharp: any;
declare var app: any;

@Injectable()
export class LauncherService implements OnDestroy {

    argument: string[] =  [
        '/auth_ip:rappelz-equation.fr',
        '/auth_port:4500',
        '/locale:windows-1252',
        '/country:FR',
        '/cash',
        '/commercial_shop',
        '/help_url_w:611',
        '/help_url_h:625',
        '/cash_url_w:900',
        '/cash_url_h:650',
        '/secondres:"./Second/"',
        '/allow_double_exec:{double_exec}',
        '/use_nprotect:0',
        '/imbclogin',
        '/account:{username}',
        '/password:{password}'
    ];
    
    env: string = '';
    ready: boolean;
    status: string = '';
    value: number = 0;

    constructor(
        private ngZone: NgZone) {
        CefSharp.BindObjectAsync("app");

        app = window.app || {};
        app.active = this.active.bind(this);
        app.setStatus = this.setStatus.bind(this);
        app.setEnv = this.setEnv.bind(this);
        app.setProgress = this.setProgress.bind(this);
    }

    ngOnDestroy() {
		app.active = null;
		app.setProgress = null;
		app.setEnv = null;
		app.setStatus = null;
    }

    /**
     * Active ou désactive le button jouer
     */
    active(state: boolean = true): void {
        this.ngZone.run(() => this.ready = state);
    }

    /**
     * Evènement de fermeture fenêtre
     */
    close(): void {
        if (app)
            app.close();
    }

    /**
     * Evènement d'erreur
     */
    error(message: string, critical: boolean = false): void {
        if (app)
            app.error(message, critical);
    }

    /**
     * Evènement de préchargement de page complété
     */
    loaded() {
        if (app)
            app.loaded();
    }

    /**
     * Evènement de reduction fenêtre
     */
    minimize() {
        if (app)
            app.minimize();
    }

    /**
     * Evènement de navigation vers un lien
     */
    navigate(url: string) {
        if (app)
            app.navigate(url);
    }

    /**
     * Evènement de vérification complet
     */
    reChecking() {
        if (app)
            app.rechecking();
    }

    /**
     * Change la progression
     */
    setEnv(env: string): void {
        this.ngZone.run(() => this.env = env);
    }

    /**
     * Change la progression
     */
    setProgress(value: number): void {
        this.ngZone.run(() => {
            this.status = '';
            this.value = value;
        });
    }

    /**
     * Change le status du launcher
     */
	setStatus(value: string): void {
		this.ngZone.run(() => {
            this.status = value
            this.value = 0;
        });
    }

    /**
     * Evènement de paramètrage du jeu
     */
    setting(lang: string) {
        if (app)
            app.setting(lang);
    }
    
    /**
     * Evènement de lancement du jeu
     */
    start(username :string, password: string, lang: string) {
        if (app) {
            let doubleExec : string = "0";

            if (this.env == 'Development') {
                doubleExec = "1";
            }

            var args = this.argument
                .join(' ')
                .replace('{double_exec}', doubleExec)
                .replace('{username}', username)
                .replace('{password}', password);

            app.start(args, lang);
        }
    }
}