import { Component, OnInit, Input, ContentChild, TemplateRef } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
    selector: '[link-effect-4]',
    template: `
    <ng-template #inner>
        <span class="link-effect-inner">
            <span class="link-effect-l">
                <ng-template [ngTemplateOutlet]="source"></ng-template>
            </span>
            <span class="link-effect-r">
                <ng-template [ngTemplateOutlet]="source"></ng-template>
            </span>
            <span class="link-effect-shade">
                <ng-template [ngTemplateOutlet]="source"></ng-template>
            </span>
        </span>
    </ng-template>

    <ng-container *ngTemplateOutlet="inner"></ng-container>`
})

export class LinkEffet4Component {
    @ContentChild(TemplateRef) source: TemplateRef<any>;
}