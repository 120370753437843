import { Component, OnInit, Input, ChangeDetectionStrategy, AfterViewInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Topic } from '../forum/topic.model';
import { TranslateService } from 'ng2-translate';
import { Subscription } from 'rxjs';
import { LauncherService } from '../launcher/launcher.service';

@Component({
    selector: '[topic-view]',
    templateUrl: 'topic-view.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class TopicViewComponent implements AfterViewInit, OnInit, OnDestroy {
    private subscription: Subscription = new Subscription();

    @Input() topic: Topic;    
    
    constructor(
        private cdr: ChangeDetectorRef,
        private launcher: LauncherService,
        public lang: TranslateService) { }

    ngOnInit(): void {
        this.subscription.add(this.lang.onLangChange.subscribe(
            () => this.cdr.detectChanges()
        ));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
        
    ngAfterViewInit(): void {
        this.cdr.detach();
    }

    open() {
        let url = this.topic.getUrl();
        this.launcher.navigate(url)
    }
}