import { Component, OnInit, Input, ContentChild, TemplateRef } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
    selector: '[link-effect-1]',
    template: `
    <ng-container *ngFor="let char of chars">
        <span>{{ char }}</span>
    </ng-container>`
})

export class LinkEffet1Component implements OnInit {
    @Input() text: string;
    chars: any[]

    ngOnInit() {
        this.chars = Array.from(this.text)
    }
}