import { Component, OnInit, OnDestroy } from '@angular/core';
import { Topic } from '../forum/topic.model';
import { Subscription } from 'rxjs';

import { ForumService } from '../forum/forum.service';
import { TranslateService } from 'ng2-translate';
import { UserService } from '../user/user.service';
import { LauncherService } from '../launcher/launcher.service';

declare var $: any;

@Component({
    selector: "rule",
    templateUrl: 'rule.component.html'
})

export class RuleComponent implements OnInit, OnDestroy {
    loading: boolean = true;
    topic: Topic = new Topic();
    validating: boolean;

    private subscription: Subscription = new Subscription();

    constructor(
        private lang: TranslateService,
        private launcher: LauncherService,
        private service: ForumService,
        private user: UserService) { }

    ngOnInit() {
        this.loading = true;
        this.subscription.add(this.service.getRules().subscribe(
            topic => {
                this.topic = Object.assign(new Topic(), topic);
                this.loading = false;

                $('#ruleModal').modal('show');
            }
        ));
    }

    agree() {
        this.validating = true;
        this.user.setAcceptRule().subscribe(
            () => $('#ruleModal').modal('hide')
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    open() {
        this.launcher.navigate(this.topic.getUrl() + '&do=getNewComment');
    }
}