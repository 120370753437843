import { Injectable } from '@angular/core';
import { IProfile, IUser, IClaim } from './user.model'

import { TranslateService } from 'ng2-translate';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class UserProfile extends IProfile {
    
    private langs: string[] = [ 'fr', 'en' ];
    private defaultLang: string = 'en'

    constructor(
        private translate: TranslateService,
        private router: Router) {
        super();

        this.translate.addLangs(this.langs);
        this.translate.setDefaultLang(this.defaultLang);

        let lang = this.getLanguage();
        this.translate.use(lang);
    }

    isAuthorized() {
        this.load();
        if (this.token == "" || this.token == null) return false;
        if (this.refreshToken == "" || this.refreshToken == null) return false;
        if (this.user == null) return false;
        if (this.isTokenExpirated()) {
            this.resetProfile();
            this.router.navigate(['']);
            return false;
        }
        return true;
    }

    isTokenExpirated() {        
        return new Date(this.refreshTokenExpiration) < new Date();
    }

    changePseudo(pseudo: string) {
        let pseudoLockedEnd = new Date();
        pseudoLockedEnd.setMonth(pseudoLockedEnd.getMonth() + 1);

        this.user.pseudo = pseudo;
        this.user.pseudoLockoutEnd = pseudoLockedEnd.toString();
        
        this.setUser(this.user);
    }

    getAuthToken() {
        return this.token;
    }

    getLanguage() : string {
        let browerLang = this.translate.getBrowserLang();
        let language = localStorage.getItem('language');

        if (this.langs.find(lang => lang === language)) {
            return language;
        } else if (this.langs.find(lang => lang === browerLang)) {
            return browerLang;
        }
        return this.defaultLang;
    }

    hasPermission(roles: string[]) : boolean {
        for (var i = 0; i < roles.length; i++) {
            if (this.roles.find(r => r == roles[i])) {
                return true;
            }
        }
        return false;
    }

    load(authorizationOnly: boolean = false): IProfile {
        this.claims = JSON.parse(sessionStorage.getItem('claims'));
        this.language = localStorage.getItem('language');
        this.token = sessionStorage.getItem('token');
        this.tokenExpiration = sessionStorage.getItem('tokenExpiration');
        this.refreshToken = sessionStorage.getItem('refreshToken');
        this.refreshTokenExpiration = sessionStorage.getItem('refreshTokenExpiration');
        this.roles = JSON.parse(sessionStorage.getItem('roles'));
        this.user = JSON.parse(sessionStorage.getItem('user'));
        
        return this;
    }

    resetProfile(): IProfile {
        sessionStorage.clear();

        this.claims = [];
        this.token = '';
        this.tokenExpiration = '';
        this.refreshToken = '';
        this.refreshTokenExpiration = '';
        this.user = new IUser();

        return this;
    }

    setAvatar(avatar: string) {
        this.user.avatar = avatar;
        this.setUser(this.user);
    }

    setMoney(money: number) {
        this.user.money = money;
        this.setUser(this.user);
    }

    setLanguage(lang: string): void {
        this.translate.use(lang).subscribe(
            () => {
                this.language = lang;
                if (this.user != null) {
                    this.user.language = lang;
                }
                localStorage.setItem('language', lang);
            }
        );
    }

    setProfile(profile: IProfile): void {
        this.setToken(profile);
        this.setLanguage(profile.user.language);
        this.setRole(profile.roles);
        this.setUser(profile.user);
    }

    setRuleTime() {
        this.user.ruleAccept = Math.round((Date.now() / 1000));
        this.setUser(this.user);
    }

    setToken(jwt: any) {
        sessionStorage.setItem('token', jwt.token);
        sessionStorage.setItem('tokenExpiration', new Date(Date.now() + jwt.tokenExpiration * 1000).toString());
        sessionStorage.setItem('refreshToken', jwt.refreshToken);
        sessionStorage.setItem('refreshTokenExpiration', new Date(Date.now() + jwt.refreshTokenExpiration * 1000).toString());
        sessionStorage.setItem('claims', JSON.stringify(jwt.claims));
    }

    setRole(role) {
        sessionStorage.setItem('roles', JSON.stringify(role));
    }

    setUser(user: IUser) {
        sessionStorage.setItem('user', JSON.stringify(user));
    }
}